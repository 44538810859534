<template>
  <div>
    <p class="qa_title">1. What actions are considered screen switching?</p>
      <p class="qa_content">
        System pop-ups, minimizing the browser, clicking outside the browser window, using translation tools, engaging with chat software, opening browsers other than Google Chrome, and running antivirus software like Security Guard are all detected as screen switches by the system.
      </p>
      <p class="qa_title" style="margin-top: 12px">2. How is cheating decided? </p>
      <p class="qa_content">The system will record screen switches and assess if cheating occurred based on the number, frequency, and duration. If cheating is detected, the system will automatically submit your test, regardless of completion.</p>

      <p class="qa_title" style="margin-top: 12px">3. Will an accidental screen switch impact my test score?</p>
      <p class="qa_content">creen switch does not affect scores directly. Abnormal screen switches will be reviewed manually. As long as there is no actual cheating or rule violation, it will not impact your grade.</p>

      <p class="qa_title" style="margin-top: 12px">4. Why is the mouse cursor hidden?</p>
      <p class="qa_content">The cursor is hidden by design to prevent the use of translation plugins.</p>

      <p class="qa_title" style="margin-top: 12px">5. Can I use the bathroom during the test?</p>
      <p class="qa_content">Yes, but please limit your break to 2 minutes.</p>

      <p class="qa_title" style="margin-top: 12px">6. Can I submit the exam early?</p>
      <p class="qa_content">Yes, you can submit your exam before time ends.</p>

      <p class="qa_title" style="margin-top: 12px">7. Can I refresh the page if it goes blank or stalls?</p>
      <p class="qa_content">Yes, but check your network connection before doing so.</p>

      <p class="qa_title" style="margin-top: 12px">8. What if I close the page by mistake?</p>
      <p class="qa_content">If you accidentally close the page, you can re-enter the test from the home page. Your answers are saved in real-time as long as the network is stable. Please verify the status of your answers after re-entry.</p>

  </div>
</template>

<script>
export default {
  name: "QA"
}
</script>

<style scoped>
.qa_title {
  color: #484949;
  font-size: 16px;
  font-weight: bold;
}

.qa_content {
  margin-top: 10px;
  color: #707170;
  font-size: 14px;
  line-height: 22px;
}

</style>